@font-face {
    font-family: "Latam_Sans_Hairline";
    src: local(Latam_Sans_Hairline), url("/assets/font/Latam-Sans/Latam_Sans_Hairline.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Hairline.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Hairline_Italic";
    src: local(Latam_Sans_Hairline_Italic), url("/assets/font/Latam-Sans/Latam_Sans_Hairline_Italic.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Hairline_Italic.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Light";
    src: local(Latam_Sans_Light), url("/assets/font/Latam-Sans/Latam_Sans_Light.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Light.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Light_Italic";
    src: local(Latam_Sans_Light_Italic), url("/assets/font/Latam-Sans/Latam_Sans_Light_Italic.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Light_Italic.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Regular";
    src: local(Latam_Sans_Regular), url("/assets/font/Latam-Sans/Latam_Sans_Regular.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Regular.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Regular_Italic";
    src: local(Latam_Sans_Regular_Italic), url("/assets/font/Latam-Sans/Latam_Sans_Regular_Italic.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Regular_Italic.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Bold";
    src: local(Latam_Sans_Bold), url("/assets/font/Latam-Sans/Latam_Sans_Bold.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Bold.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Bold_Italic";
    src: local(Latam_Sans_Bold_Italic), url("/assets/font/Latam-Sans/Latam_Sans_Bold_Italic.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Bold_Italic.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Black";
    src: local(Latam_Sans_Black), url("/assets/font/Latam-Sans/Latam_Sans_Black.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Black.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Black_Italic";
    src: local(Latam_Sans_Black_Italic), url("/assets/font/Latam-Sans/Latam_Sans_Black_Italic.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Black_Italic.woff") format("woff");
}

@font-face {
    font-family: "Latam_Sans_Extended";
    src: local(Latam_Sans_Extended), url("/assets/font/Latam-Sans/Latam_Sans_Extended.woff");
    src: url("/assets/font/Latam-Sans/Latam_Sans_Extended.woff") format("woff");
}

.latam_hairline {
    font-family: "Latam_Sans_Hairline", arial, sans-serif;
}

.latam_hairline_italic {
    font-family: "Latam_Sans_Hairline_Italic", arial, sans-serif;
}

.latam_light {
    font-family: "Latam_Sans_Light", arial, sans-serif;
}

.latam_light_italic {
    font-family: "Latam_Sans_Light_Italic", arial, sans-serif;
}

.latam_regular {
    font-family: "Latam_Sans_Regular", arial, sans-serif;
}

.latam_regular_italic {
    font-family: "Latam_Sans_Regular_Italic", arial, sans-serif;
}

.latam_bold {
    font-family: "Latam_Sans_Bold", arial, sans-serif;
}

.latam_bold_italic {
    font-family: "Latam_Sans_Bold_Italic", arial, sans-serif;
}

.latam_black {
    font-family: "Latam_Sans_Black", arial, sans-serif;
}

.latam_black_italic {
    font-family: "Latam_Sans_Black_Italic", arial, sans-serif;
}

.latam_extended {
    font-family: "Latam_Sans_Extended", arial, sans-serif;
}

body {
    font-family: "Latam_Sans_Regular", sans-serif !important;
    font-weight: 300;
}

.font-10 {
    font-size: 10px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-20 {
    font-size: 20px;
}