@import "@angular/material/prebuilt-themes/indigo-pink.css";

:root {
    --blue-pilot: #1B0088;
    --gray-pilot: #4E6574;
    --red-pilot: #E8114B;
    --purple-pilot: #6617A3;
    --white-pilot: #ffffff;
    --indigo-pilot: #4658DF;
    --red-header-pilot: #E95878;
    --button-color:  #E8114B;
    --background-gradient: linear-gradient(101.84deg, #10004F 2.78%, #1B0088 98.95%);
}

.btns-confirm-alert {
    border-radius: 8px;
    font-size: 12px;
    padding: 5px 20px 5px 20px;
    width: 49%;
}

.btn-confirm {
    background-color: var(--blue-pilot);
    border: 1px solid var(--blue-pilot);
    color: #fff;
}

.btn-accept{
    color: #1b0088;
    border: 1px solid;
}

.btn-outline{
    background-color: transparent;
    font-size: 12px;
    font-family: 'Latam_Sans_Regular', arial, sans-serif;
}

.btn-flat{
    color: white;
    background-color: #1b0088;
    font-family: 'Latam_Sans_Regular', arial, sans-serif;
}

.btn-outline, .btn-flat {
    padding: 8px 15px;
    border-radius: 8px;
}

.btn-cancel {
    background-color: #fff;
    border: 1px solid var(--blue-pilot);
    color: var(--blue-pilot);
}

.swal-pilot .swal2-title {
    align-items: flex-start !important;
    padding: 0;
}

.swal-pilot #swal2-title {
    font-size: 1.1rem;
    color: #10004F;
    font-family: "Latam_Sans_Bold", arial, sans-serif;
}

.swal-pilot .title-center {
    text-align: center !important;
}
.swal-pilot .title-left {
    text-align: left !important;
}

.swal-pilot #swal2-html-container {
    font-size: .9rem;
    text-align: left !important;
    margin: 1rem 0;
}

.swal-pilot {
    max-width:90% !important;
    padding: 1.5rem;
    border-radius: 15px;
}

.swal-pilot .swal2-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    justify-content: space-evenly !important;
    margin: 0 !important;
    z-index: 1;
}

.swal-pilot .swal2-close {
    position: absolute;
    top: 0;
    right: 0;
}

.title-section{
    padding: 5rem 0 0;
    margin: 0;
    color: #4e6574;
}

.title-label{
    font-size: 16px;
    text-transform: uppercase;
}


/* General Styles for BTNS Pilot */
.pilot-primary {
    background: var(--blue-pilot);
}

.pilot-secondary {
    background: var(--red-pilot);
    color: #fff !important;
}

.pilot-outline {
    border-radius: 8px;
    background: transparent;
    color: var(--blue-pilot);
    border: 1px solid var(--blue-pilot) !important;
    // height: 40px;
    font-family: "Latam_Sans_Bold", arial, sans-serif;
    padding: 7px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-pilot {
    border-radius: 8px;
    /* width: 203px; */
    height: 40px;
    font-family: "Latam_Sans_Bold", arial, sans-serif;
    padding: 7px 32px;
    border: none !important;
    color: #fff;
}

.btn-pilot[disabled], button[disabled] {
    opacity: 0.4;
}

/* snackbar dialog */

.mat-mdc-snack-bar-container {
    margin: calc(env(safe-area-inset-top) + 5rem) 1rem 0 !important;
    padding: 0 !important;
    --mat-mdc-snack-bar-button-color: #ffffff;
    --mdc-snackbar-supporting-text-color: #ffffff;
  
    &.app-notification-pilot {
      --mdc-snackbar-container-color: var(--blue-pilot);
    }
}

.extra-top {
    margin-top: 1rem;
}

.no-connection-message {
    background-color: #EB433A;
    color: #fff;
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 18px;
    z-index: 99999;
    font-size: 12px;
}

// MatBadge Pilot

.mat-badge-content {
    background: #EB5757 !important;
    color: white;
    font-size: 8px !important;
}

// Spinner Pilot

.loading-spinner {
    margin: 3rem 0;
    p {
        margin: 1rem 0;
    }

    mat-spinner {
        stroke: var(--blue-pilot) !important
    }
}

label {
    color: #6f8899;
}

// Dialog Modal
.modal-header-dialog {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
    color: var(--blue-pilot);
}

.modal-title-dialog {
    font-size: 1.2rem !important;
    color: var(--blue-pilot) !important;
    font-family: "Latam_Sans_Bold", arial, sans-serif !important;
    padding: 0 0 9px 1rem !important;
}



// Pilot Tools

.btn-default {
    border: 2px solid rgba(71, 139, 183, .3)!important;
    background-color: transparent!important;
    color: #748c9d;
    border-radius: 8px;
    width: 203px;
    height: 40px;
    font-family: Latam_Sans_Bold, arial, sans-serif;
    box-shadow: none;
}

.btn-default:focus,
.btn-default:hover {
    box-shadow: 0 5px 11px 0 rgba(41, 92, 125, .2);
    outline: 0;
}
