html,
body {
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-drawer-container {
  background-color: #F6F9FF !important;
}

button {
  cursor: pointer;
}

input[type="image"] {
  outline: none;
}

button:focus {
  outline: none;
  cursor: pointer;
}

.bg-color-gray {
  background-color: #f6f9ff !important;
}

.w-100 {
  width: 100%;
}

.border-radius-none {
  border-radius: 0px !important;
}

.md-form .form-control {
  display: block;
  width: 100%;
  height: calc(2rem + 2px) !important;
  padding: 0px;
}

.btn-floating i {
  display: block;
  width: inherit;
  text-align: center;
  font-size: 1.5rem;
  line-height: 47px;
  /* color: #fff; */
}

.btn-floating {
  -webkit-box-shadow: 0 2px 8px 0px rgba(176, 192, 237, 0.5);
  box-shadow: 0 2px 8px 0px rgba(176, 192, 237, 0.5);
  width: 47px;
  height: 47px;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  border: none;
  cursor: pointer;
}

.btn {
  box-shadow: 0 4px 15px 0 rgba(71, 139, 183, 0.2);
  border-radius: 6px;
  font-size: 16px;
  padding: 7px 32px 4px;
  outline: none;
  border: none;
  text-transform: none;
}

.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: 0 5px 11px 0 rgba(41, 92, 125, 0.2);
  outline: 0;
}

.btn:not([disabled]):not(.disabled):active,
.btn:not([disabled]):not(.disabled).active {
  box-shadow: 0 5px 11px 0 rgba(41, 92, 125, 0.2);
}

.btn-latam {
  background-image: linear-gradient(-135deg, #61a4ce 0%, #4489b5 100%);
  border-radius: 6px;
  font-size: 16px;
  padding: 8px 32px;
  outline: none;
  border: none;
  text-transform: none;
}

.btn-latam:hover,
.btn-latam:active,
.btn-latam:focus {
  background-image: linear-gradient(-135deg, #61a4ce 0%, #4489b5 100%);
}

.btn-latam-disabled {
  background: #d5dee4;
  border-radius: 6px;
  font-size: 16px;
  padding: 8px 32px;
  outline: none;
  border: none;
  text-transform: none;
}

.btn-mdb-color-pl:disabled{
  background: var(--mdc-protected-button-disabled-container-color, rgba(0, 0, 0, 0.12)) !important;
  color: var(--mdc-protected-button-disabled-label-text-color, rgba(0, 0, 0, 0.38)) !important;
  box-shadow: var(--mdc-protected-button-disabled-container-elevation, 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12));
}

.btn-mdb-color-pl:not([disabled]):not(.disabled):active,
.btn-mdb-color-pl:not([disabled]):not(.disabled).active,
.show > .btn-mdb-color-pl.dropdown-toggle {
  box-shadow: 0 4px 15px 0 rgba(71, 139, 183, 0.3);
  background-color: #d0115a;
}

.btn-mdb-color-pl:hover {
  background-color: #f31d6f;
}

.btn-mdb-color-pl:focus {
  background-color: #d0115a;
}

.mh-7 {
  min-height: 700px;
}

.t-white {
  color: #fff;
}

.t-light-purple {
  color: #decbfb;
}

.t-gray-1 {
  color: #d5dee4;
}

.t-gray-2 {
  color: #9aaeba;
}

.t-gray-3 {
  color: #6f8899;
}

.t-gray-4 {
  color: #4e6574;
}

.t-gray-5 {
  color: #1d4864;
}

.t-gray-6 {
  color: #505f66;
}

.t-gray-7 {
  color: #486573;
}

.t-active-blue {
  color: #478bb7;
}

.t-red {
  color: #ff1e74;
}

.mdi-14px {
  font-size: 14px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-38 {
  font-size: 38px !important;
}

/*Sidebar menu*/

.sidebar-fixed {
  height: 100vh;
  width: 200px;
  -webkit-box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  z-index: 1050;
  background-color: #1d4864;
  padding: 1.5rem;
  padding-top: 0;
}

.sidebar-fixed .list-group .active {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.sidebar-fixed .logo-wrapper {
  padding: 2.5rem;
}

/*Sidebar menu*/

.navbar.scrolling-navbar {
  /* z-index: 1040;  */
  box-shadow: none; 
  -webkit-box-shadow: none;
}

.card-generic {
  background-color: #fff;
  -webkit-box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
  box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
  border: 0;
  border-radius: 6px;
  font-weight: 400;
}

.card-generic p.font-14 {
  position: relative;
  color: #fff;
  line-height: 15px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.card-generic h5 {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #fff;
}

.view {
  border-radius: 6px;
}

.h-card {
  max-height: 270px;
}

.overlay .mask {
  opacity: 1;
  border-radius: 6px;
}

.overlay .mask:hover {
  opacity: 1;
}

.safety-gradient,
.fuel-gradient,
.flight-report-gradient {
  background-color: rgba(78, 101, 116, 0.8);
}

.safety-gradient:hover,
.fuel-gradient:hover,
.flight-report-gradient:hover {
  background-color: rgba(78, 101, 116, 0.9);
}

.card-disabled {
  opacity: 0.1;
  background: #d5dee4;
  border-radius: 6px;
}

.adjustment-img-report {
  top: 0px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
span.mat-checkbox-label {
  color: #6e8899 !important;
  font-size: 14px !important;
  font-family: "Latam_Sans_Regular";
  padding-top: 9px;
}


.btn-mdb-color-pl {
  background-color: #ff1e74;
  border-radius: 20px;
  width: 203px;
  height: 40px;
  font-family: "Latam_Sans_Bold", arial, sans-serif;
  padding: 7px 32px;
  border: none !important;
  color: #fff;
}

.btn-mdb-color-grey-pl {
  border: 2px solid rgba(71, 139, 183, 0.3) !important;
  background-color: transparent !important;
  color: #748c9d;
  border-radius: 20px;
  width: 203px;
  height: 40px;
  font-family: "Latam_Sans_Bold", arial, sans-serif;
  box-shadow: none;
}

.btn-mdb-color-secondary {
  border: 2px solid rgba(71, 139, 183, 0.3) !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff;
  border-radius: 20px;
  width: 203px;
  height: 40px;
  font-family: "Latam_Sans_Bold", arial, sans-serif;
  box-shadow: none;
}

.swap-route {
  position: absolute;
  left: -11px;
  top: 15px;
  cursor: pointer;
  color: #e24e72;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-weight-700 {
  font-weight: 700;
}

.mat-expansion-indicator::after {
  color: #ff1e74 !important;
}

/*fuel*/

.d-flex-sb.border-fleet:before {
  content: "";
  position: absolute;
  width: 10px;
  background: #67d3fd;
  height: 100%;
  left: 15px;
  border-radius: 6px 0px 0px 6px;
}

.d-flex-sb.border-pilot:before {
  content: "";
  position: absolute;
  width: 10px;
  background: #ff1e74;
  height: 100%;
  left: 15px;
  border-radius: 6px 0px 0px 6px;
}

.d-flex-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 0px !important;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 5px 40px -10px rgba(176, 192, 237, 0.7);
  height: 53px;
  padding-left: 15px;
}

p.text-center.latam_bold.t-gray-4.font-18.mb-0 {
  margin-left: 20px;
}

.text-middle-card-fuel {
  display: table-cell;
  height: 50px;
}

.card-generic.border-cian::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 10px;
  height: 100%;
  display: block;
  background-image: linear-gradient(180deg, #76b1ff 0%, #7676ff 100%);
  border-radius: 6px 0 0 6px;
}

.card-generic.border-blue::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 10px;
  height: 100%;
  display: block;
  background-image: linear-gradient(192.56deg, #8ed6fa 0%, #67bce7 100%);
  border-radius: 6px 0 0 6px;
}

.ngx-mat-drp-date-display {
  min-width: inherit !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.ngx-mat-drp-date-display .mat-form-field-infix {
  width: max-content;
}

.ngx-mat-drp-overlay {
  overflow-y: auto;
}

.ngx-mat-drp-date-input {
  color: #000000 !important;
}

/*fuel*/

/*navbar*/

.content-custom-navBar {
  min-height: 91px;
}

.user-image {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 8px 0px rgba(176, 192, 237, 0.5);
  -moz-box-shadow: 0 2px 8px 0px rgba(176, 192, 237, 0.5);
  box-shadow: 0 2px 8px 0px rgba(176, 192, 237, 0.5);
  /* border: 3px solid #fff; */
  border: 3px solid #FCD462;
}

/*navbar*/

/********scroll******/

/* width */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  /*background: #f1f1f1;*/
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #d5dee4;
  border-radius: 10px;
  width: 6px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #cacfd9;
}

/********scroll******/

/******** Slider******/

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #d5dee4;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #e91e63;
}

.stabilization-altitude .mat-mdc-form-field {
  display: block;
}


.mat-mdc-paginator-range-label {
  margin-left: 0px;
}

/******** Slider******/

@media (max-width: 991px) {
  .h-card {
    max-height: 240px;
  }
  .adjustment-img-report {
    top: -110px;
  }
}

@media (max-width: 767px) {
  .sidebar-fixed {
    display: none;
  }
  .h-card {
    max-height: 200px;
  }
  .adjustment-img-report {
    top: -70px;
  }
}

@media (max-width: 480px) {
  .h-card {
    max-height: 120px;
  }
  .card h5 {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    left: 20px;
  }
  .adjustment-img-safety {
    top: -50px;
  }
  .adjustment-img-fuel {
    top: -40px;
  }
  .adjustment-img-report {
    top: -50px;
  }
}

@media (max-width: 300px) {
  .adjustment-img-safety {
    top: -50px;
  }
  .adjustment-img-fuel {
    top: -30px;
  }
  .adjustment-img-report {
    top: -30px;
  }
}

/* Safety tabs */

.mat-mdc-tab-header,
.mat-mdc-tab-nav-bar {
  border-bottom: none;
}

.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 3px !important;
  transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-ink-bar.mat-ink-bar {
  background-color: var(--blue-pilot) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-labels {
  margin: 0px !important;
  border-bottom: 1px solid #dee2e6 !important;
  opacity: 1 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label:focus:not {
  color: red !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label {
  border-radius: 6px 6px 0px 0px;
  text-transform: uppercase;
  opacity: 1 !important;
  font-size: 16px;
  font-family: "Latam_Sans_Bold_Italic" !important;
  color: #4e6573 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label:focus:not {
  color: red !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
  overflow-x: hidden !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: flex;
  box-shadow: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-header-pagination-chevron {
  border-color: #6c757d;
}
/* safety indicators Containers */

main.indicator_container {
  padding: 15px;
  margin: 0px 0px 15px 0px;
  background: rgb(255, 255, 255);
  border: 2px solid rgb(246, 249, 255);
  border-radius: 6px;
  box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
  -webkit-box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
}

.not-found {
  padding: 15px;
  background: #f3f6f8;
  font-size: 14px;
  border-radius: 6px;
  color: #6f8899;
  margin: 10px 0px 20px;
}

.rounded-corners {
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
  -webkit-box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
  margin-bottom: 50px;
  background: #ffffff;
}

.inputFleet {
  background: #fff;
  padding: 30px 0px 10px;
  text-align: center;
  margin: 0px auto 15px;
  box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
  -webkit-box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7);
  border-radius: 6px;
}

ol.ranking.airport {
  padding: 0px 19px;
}

ol.ranking.airport li {
  padding-left: 8px;
  line-height: 24px;
}

.indicators_data {
  min-height: 180px;
}

.indicator_container h5 {
  font-size: 14px;
  margin: 0;
}

.indicator_container ol.ranking.airport {
  padding: 15px;
}

.font-24 {
  font-size: 20px !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
  font-size: 16px !important;
  padding: 7px 32px 4px;
  outline: none !important;
  border: none !important;
  text-transform: none;
  background: var(--blue-pilot);
  border-radius: 10px !important;
  box-shadow: 0 4px 15px 0 rgba(71, 139, 183, 0.30000001192092896);
  height: 40px;
}

.swal2-popup .swal2-styled.swal2-confirm,
.swal2-popup .swal2-styled.swal2-confirm:active,
.swal2-popup .swal2-styled.swal2-confirm:focus {
  font-size: 16px !important;
  padding: 7px 32px 4px;
  outline: none !important;
  border: none !important;
  text-transform: none;
  background: var(--blue-pilot);
  border-radius: 10px !important;
  box-shadow: 0 4px 15px 0 rgba(71, 139, 183, 0.30000001192092896);
  height: 40px;
}

.swal2-popup .swal2-styled.swal2-cancel {
  font-size: 16px !important;
  padding: 7px 32px 4px;
  text-transform: none;
  box-shadow: 0 4px 15px 0 rgba(71, 139, 183, 0.30000001192092896);
  height: 40px;
  border: .5px solid var(--blue-pilot) !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  color: var(--blue-pilot) !important;
}

.swal2-popup .swal2-styled.swal2-cancel:hover,
.swal2-popup .swal2-styled.swal2-cancel:focus,
.swal2-popup .swal2-styled.swal2-cancel:active {
  padding: 7px 32px 4px;
  text-transform: none;
  box-shadow: 0 4px 15px 0 rgba(71, 139, 183, 0.30000001192092896);
  height: 40px;
  border: .5px solid var(--blue-pilot) !important;
  border-radius: 10px !important;
  background-color: #eaf0f4 !important;
  color: var(--blue-pilot) !important;
}

.swal2-container.swal2-shown {
  background: rgba(29, 72, 100, 0.3) !important;
}

.swal2-popup {
  border-radius: 6px !important;
  box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7) !important;
  -webkit-box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7) !important;
}

.mat-mdc-dialog-container {
  border-radius: 6px !important;
  box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7) !important;
  -webkit-box-shadow: 0 10px 30px -10px rgba(176, 192, 237, 0.7) !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(29, 72, 100, 0.3) !important;
}

.font-24 {
  font-size: 22px !important;
}

.scroll-list-box {
  border-right: 1px solid #cad1e1;
}

/* calculator*/

/* calculator */

@media (max-width: 767px) {
  .pt-login {
    width: 100%;
    text-align: center;
  }
  .pt-login button {
    width: 100%;
    margin: 0 auto;
    max-width: 280px;
  }
  .detail-table {
    padding: 0px !important;
  }
  .font-24 {
    font-size: 18px !important;
  }
  .box-content.ml-5.mt-4 {
    margin-left: 15px !important;
  }
}

.show-on-575 {
  display: none;
}

@media (max-width: 575px) {
  .show-on-575 {
    display: flex;
  }
  .mat-mdc-slider {
    margin-top: 5px !important;
    margin-bottom: 16px;
    max-width: 400px !important;
  }
  .hide-on-575 {
    display: none;
  }
  .mat-mdc-tab-header {
    overflow: initial !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    min-width: 160px !important;
  }
  .rounded-corners {
    overflow: initial !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-wrapper {
    overflow: initial !important;
  }
  .scroll-list-box {
    border-right: none;
    overflow: initial !important;
    max-height: auto !important;
    margin-bottom: 50px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    overflow-x: initial !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    overflow: auto !important;
    overflow-x: hidden !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab-body.mat-tab-body-active {
    overflow-y: auto !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab-body.mat-tab-body-active .table-responsive {
    overflow-x: hidden !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab-body.mat-tab-body-active::-webkit-scrollbar {
    display: none;
  }
  .list-group .list-group-item-new:first-child {
    border-bottom: 1px solid #dde5f7 !important;
  }
}

@media (max-width: 480px) {
  .pt-login button {
    width: 100%;
  }
  .font-24 {
    font-size: 16px !important;
  }
  main h4 {
    font-size: 16px !important;
  }
}

@media (max-width: 480px) {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    min-width: 100px !important;
  }
}

.mat-mdc-snack-bar-container {
  border-radius: 0px !important;
  background-color: rgba(0, 0, 0, 0.77);
  font-weight: 400;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.select-fleet .mat-select-value,
.select-profile .mat-select-value,
.select-profile .mat-select-arrow,
.select-fleet.mat-form-field-appearance-legacy .mat-form-field-label,
.select-profile.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #ffffff;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.select-fleet.mat-form-field-appearance-legacy .mat-form-field-underline,
.select-profile.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #ffffff;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.route-inputs .mat-form-field-infix {
  border-bottom: 2px solid #fff;
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.select-fleet .mat-select-arrow {
  color: rgb(255, 255, 255);
}

.mat-drawer-container {
  height: 100%;
}

.progress-bar-home.mat-mdc-progress-bar {
  height: 4px;
  top: 63px;
  position: fixed;
  z-index: 99999;
  left: 0;
}

@media (max-width: 599px) {
  .progress-bar-home.mat-mdc-progress-bar {
    top: 63px !important;
  }
}

.progress-bar-home.mat-mdc-progress-bar .mat-progress-bar-buffer,
.progress-bar-modal.mat-progress-bar .mat-progress-bar-buffer {
  background-color: #feddf0;
}

.progress-bar-home.mat-mdc-progress-bar .mat-progress-bar-fill::after,
.progress-bar-modal.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: var(--blue-pilot);
}

.content-burger {
  margin: 0px 0 0 5px !important;
  color: #fff;
}

.mat-mdc-slider {
  margin-top: -32px;
}

.home-menu.mat-mdc-menu-panel {
  min-width: 166px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox .mat-checkbox-frame{
  border-color: #6E8899 !important;
  border-radius: 6px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.checkbox-terms .mat-checkbox-frame {
  border-color: #f8f9fa;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.checkbox-terms .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #f8f9fa;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.checkbox-terms .mat-checkbox-checkmark-path {
  stroke: #ff1e74 !important;
}

.swal2-popup .swal2-content {
  color: #6f8899 !important;
}

.swal2-footer a:hover {
  color: #ff1e74 !important;
}



/*Hotjar Feedback*/

#_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label ._hj-f5b2a1eb-9b07_icon_emotion_default {
  display: none !important;
  margin: 10px 0 0 -3px;
  font-size: 11px;
}

#_hj-f5b2a1eb-9b07_feedback
  ._hj-f5b2a1eb-9b07_feedback_minimized_label
  ._hj-f5b2a1eb-9b07_feedback_minimized_label_text {
  color: #fff;
  font-family: "Latam_Sans_Black", sans-serif !important;
}

#_hj-f5b2a1eb-9b07_feedback[data-minimized-position="middle_right"] ._hj-f5b2a1eb-9b07_feedback_minimized_label {
  right: 0px !important;
  border-radius: 6px 0px 0px 6px !important;
}

#_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label ._hj-f5b2a1eb-9b07_icon_emotion_default {
  display: none !important;
  opacity: 0 !important;
}

#_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label:hover {
  -webkit-box-shadow: 0 0 35px 1px rgba(255, 16, 115, 0.3) !important;
  -moz-box-shadow: 0 0 35px 1px rgba(255, 16, 115, 0.3) !important;
  box-shadow: 0 0 35px 1px rgba(255, 16, 115, 0.3) !important;
}

#_hj-f5b2a1eb-9b07_feedback[data-minimized-position="middle_right"] #_hj-f5b2a1eb-9b07_feedback_minimized {
  /* bottom: 15px!important;
    right: -35px!important; */
    bottom: 58px !important;
    right: 0 !important;
}

body #_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label {
  width: 20px !important;
  padding: 5px 0px 5px 3px !important;
  background: #ff1e74;
  transition: 0.5s !important;
  font-size: 11px;
}

body #_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label ._hj-f5b2a1eb-9b07_icon_emotion_default {
  display: none !important;
  margin: 0px !important;
  font-size: 0px;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background: rgba(29, 72, 100, 0.3) !important;
  height: 100% !important;
  position: fixed !important;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.3;
}

/*///Hotjar Feedback*/

/*Calendar*/

.mat-calendar-body-selected {
  background-color: var(--blue-pilot) !important;
  color: #fff;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none !important;
}

.mat-calendar-body-cell-content {
  width: 80% !important;
  height: 80% !important;
  border-radius: 10px !important;
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
  color: var(--blue-pilot) !important;
}



/*//Calendar*/

/*INPUTS*/

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #6f8899;
  font-weight: 300;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: #6f8899;
  font-weight: 300;
}

.mat-drawer-container {
  color: #6f8899;
  font-weight: 300;
  display: grid !important;
  overflow: auto !important;
  height: 100vh !important;
}

.mat-form-field-appearance-legacy .mat-mdc-form-field-hint {
  color: #6f8899;
  font-weight: 300;
}

.mat-mdc-input-element {
  color: #6f8899;
  font-weight: 300 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value {
  color: #6f8899;
  font-weight: 300 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-underline {
  color: #6f8899;
  background-color: rgba(111, 136, 153, 0.3) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.fleet-list .mat-select-value {
  font-weight: 700 !important;
  color: #4e6574;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.fleet-list .mat-select-arrow {
  color: #ff1e74;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.fleet-list.mat-select-disabled .mat-select-arrow {
  color: rgba(204, 190, 190, 0.54);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.fleet-list.mat-select-disabled .mat-select-value {
  color: rgba(144, 127, 127, 0.7) !important;
}

/*//INPUTS*/

/*SNACKBAR*/

.mat-mdc-snack-bar-container {
  background: #1b0088f0;
  padding: 15px 0px;
  border-radius: 6px 6px !important;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mat-mdc-snack-bar-action {
  color: #ff1e74;
  font-weight: 300 !important;
}

html,
body {
  min-height: 100vh;
  margin: 0;
}

/* Dashboard */

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.dashboard {
  padding: 0px;
  overflow: hidden;
  box-shadow: none !important;
  border: 0;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

mat-card.dashboard mat-card-footer {
  margin: 0 !important;
}

mat-card.dashboard mat-card-title {
  font-size: 12px;
  font-family: "Latam_Sans_Regular", arial, sans-serif;
  color: #4e6573;
  opacity: 0.9;
  padding: 10px 10px 5px;
}

mat-card.dashboard mat-card-title::after {
  content: "";
  display: block;
  /* border-top: 1px solid #ebf0f4 !important; */
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 5px;
}

mat-card.dashboard mat-card-title i {
  width: 18px;
  height: 18px;
  margin-top: -2px;
  float: right;
  font-size: 17px;
  padding: 4px;
  background-color: #d5dee4;
  box-shadow: 0 1.5px 5px 0 rgba(213, 222, 228, 0.3);
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}

mat-card.dashboard mat-card-title i img {
  width: 4px;
  margin-top: -17px;
}

.content-information {
  width: 26px;
  height: 26px;
  margin-top: 23px;
  float: right;
  font-size: 17px;
  padding: 4px;
  background: #d5dee4;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}

.content-information.active {
  background: #4e6574;
}

.title-fuel .content-information {
  margin-top: -3px;
}

img.information {
  width: 6px;
  margin-top: -3px;
}

.mat-mdc-tooltip {
  background: #344551;
  font-family: "Latam_Sans_Regular";
  border-radius: 5px;
}

mat-card.dashboard mat-card-content,
mat-card.dashboard-roles mat-card-content {
  /* max-height: 200px;
    min-height: 200px; */
  margin: 5px 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

mat-card.dashboard-roles mat-card-content {
  max-height: 200px;
  min-height: 200px;
}

.mat-mdc-card-actions,
.mat-mdc-card-content,
.mat-mdc-card-subtitle,
.mat-mdc-card-title {
  display: block;
  margin-bottom: inherit;
}

mat-card.dashboard mat-card-content .row.col-md-6.col-12 {
  margin: auto !important;
}

mat-card.dashboard mat-card-footer {
  font-family: "Latam_Sans_Light_Italic", arial, sans-serif;
  font-size: 16px;
  padding: 10px 10px;
  color: #4e6573;
  margin-top: auto !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.dashboard span {
  font-family: "Latam_Sans_Bold_Italic", arial, sans-serif;
}

mat-card.dashboard mat-card-footer .col-4:first-child {
  border-right: 2px solid #e9eff4;
}

mat-card.dashboard mat-card-footer .col-4:last-child {
  font-family: "Latam_Sans_Regular_Italic", arial, sans-serif;
  z-index: 99;
  cursor: pointer;
}

/*mat-card.dashboard mat-card-footer .col-4:last-child::after {
    top: -5px;
    left: -5px;
    content: "";
    position: absolute;
    display: block;
    background: #edf3f4;
    width: 120%;
    height: 50px;
    z-index: -1;
    border-radius: 15px 0;
}*/

.view_more {
  padding: 0px;
  margin: 0px;
  text-align: center;
}

/* extra fuel dashboard */

.extrafuel mat-progress-bar .mat-mdc-progress-bar-fill::after {
  background-color: #fb0570 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
mat-progress-bar.cci-bar.mat-mdc-progress-bar.mat-primary .mat-progress-bar-fill::after {
  background: #61b08d !important;
}

.extrafuel mat-progress-bar .mat-mdc-progress-bar-buffer {
  background-color: #f2f6f8;
}

.mat-bottom-sheet-container {
  padding: 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-list-text {
  font-family: "Latam_Sans_Bold", arial, sans-serif;
  color: #4d6575;
}

.dashboardNoInfo {
  text-align: center;
  max-height: 170px;
  font-size: 14px;
  color: rgba(72, 101, 115, 0.7);
  padding: 10px;
  line-height: 18px;
  font-family: "Latam_Sans_Regular_Italic", arial, sans-serif;
}

.dashboardNoInfo i {
  font-size: 80px;
}

/*STABILIZATION DASHBOARD*/
.stabilization-dashboard .text-box {
  text-align: center;
  margin: 5px;
  padding: 5px;
}

.stabilization-dashboard .text-box h2 {
  font-family: "Latam_Sans_Black";
  text-transform: uppercase;
  color: #476474;
  font-size: 32px;
  margin: 0 auto;
}

.stabilization-dashboard .text-box h3 {
  color: #476474;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Latam_Sans_Extended", arial, sans-serif;
}

.stabilization-dashboard .text-box .ft {
  color: #6f8899;
  text-transform: uppercase;
  font-family: "Latam_Sans_Regular", arial, sans-serif;
  font-size: 14px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select.select-fleet-ef {
  display: block;
  width: 100px;
  position: absolute;
  right: 50px;
  z-index: 10;
  top: 10px;
  border-radius: 6px;
  padding: 2px 10px;
  /*background: #fff;
    box-shadow: 0 5px 5px -5px rgba(176, 192, 237, 0.70) !important;*/
  border: 0;
  outline: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select.select-fleet-ef.calculator {
  right: 0px;
}

table.table th,
table.table td {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-menu-panel {
  box-shadow: 0 5px 30px -5px rgba(176, 192, 237, 0.9) !important;
  border: 0;
  border-radius: 6px !important;
}

.mat-mdc-select-panel {
  box-shadow: 0 5px 30px -5px rgba(176, 192, 237, 0.9) !important;
  border: 0;
  border-radius: 6px !important;
}

mat-card.dashboard.small mat-card-content {
  min-height: 170px;
}

/*SAFETY DASHBOARD*/

.safety-dashboard .text-box {
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.safety-dashboard .text-box i img {
  width: 100%;
  max-width: 75px;
}

/*FLIGHT REPORT DASHBOARD*/

.flight-report-dashboard .text-box {
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.flight-report-dashboard .text-box i img {
  width: 100%;
  max-width: 60px;
}

span.beta-chip {
  /* background: #fff;
    padding: 5px 10px 1px;
    border-radius: 6px 6px;
    color: #673AB7;
    margin-left: 5px;
    font-size: 14px;
    font-family: "Latam_Sans_Extended", arial, sans-serif; */
  color: #ffffff;
  font-size: 9px;
  font-weight: 700;
  font-family: sans-serif;
  display: table;
  position: absolute;
  margin-left: 0px;
  margin-top: -5px;
}

.text-transparent {
  color: transparent;
}

.title {
  font-size: 16px;
  text-transform: uppercase;
  padding: 5px 0px;
  margin: 0px auto;
  color: #4e6574;
}

.title-calculator {
  text-transform: initial !important;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  padding-top: 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.card-crew .mat-tab-label {
  height: 35px;
  color: #00b5ad !important;
}

/*//SNACKBAR*/

@media (max-width: 414px) {
  .mat-mdc-snack-bar-container {
    /* border-radius: 6px 6px 0px 0px !important; */
    margin-bottom: 0px !important;
  }
  mat-card.dashboard.small mat-card-content {
    min-height: 150px;
  }
}

/*NEWSLETTER MODAL*/

.time-ago {
  font-size: 11px;
  color: #9eacba;
  float: right;
}

.type-communication {
  color: #9eacba;
  font-size: 13px;
  margin-bottom: 15px;
  text-decoration: underline;
}

.type-communication i {
  font-size: 14px;
  vertical-align: sub;
}

.communication-title {
  color: #536a78;
  font-size: 20px;
  margin-bottom: 0px;
}

.communication-content {
  color: #536a78;
  font-size: 14px;
  font-family: inherit;
  white-space: pre-wrap !important;
}

.communication-url {
  color: #2ec5c0;
  display: inline-flex;
  outline: none;
  float: right;
}

mat-dialog-content {
  overflow-x: hidden !important;
  /* display: block;
  max-height: 600px !important;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;*/
  padding: 0px !important;
  margin: 0px !important; 
}

.mat-mdc-dialog-container {
  display: block;
  padding: 15px !important;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: visible !important;
  outline: 0;
  width: 100%;
}

.mat-mdc-dialog-title {
  margin: 0 0 20px;
  display: block;
}

.mat-mdc-dialog-actions[align="end"] {
  justify-content: flex-end;
}

.mat-mdc-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 50px;
  align-items: center;
  margin-bottom: 0px !important;
}

cdk-overlay-pane {
  max-width: 90vw !important;
}

.footer-modal {
  padding: 0px;
  text-align: center;
  margin: 0px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-modal p {
  margin: 0px;
  margin-top: 10px;
}

@media (max-width: 414px) {
  .footer-modal {
    padding: 0px;
    text-align: center;
    margin: 0px auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

/*NEWSLETTER MODAL*/

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.fix-input .mat-form-field-infix {
  margin: 0px;
  border: none;
  width: auto;
}

.custom-dialog-container .mat-mdc-dialog-container {
  position: relative;
  overflow: hidden !important;
  box-shadow: none !important;
}

/*ROSTER*/

.fix-input.calculator {
  text-align: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.fix-input.calculator .mat-form-field-infix {
  padding-top: 15px;
}

.calculator-min span {
  line-height: 3;
}

/*NEWSLETTER*/

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.title-footer .mat-chip-list-wrapper {
  display: inline-block;
  margin: 0;
}

.title-footer .mat-mdc-chip.mat-mdc-standard-chip {
  padding: 4px 7px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-size: 11px;
  z-index: 2;
}

.footer-newsletter .mat-accent {
  background-color: #903393 !important;
}

.footer-newsletter .mat-primary {
  background-color: #7fc241 !important;
}

.footer-newsletter .mat-warn {
  background-color: #00b2ad !important;
}

.footer-newsletter .mat-mdc-standard-chip {
  font-style: italic;
  border-radius: 6px;
  font-weight: bold;
  font-family: "Latam_Sans_Bold_Italic", arial, sans-serif;
  padding: 4px 12px 1px 12px !important;
}

/*DASHBOARD*/

.view_more mat-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 4px 12px;
  border-radius: 24px;
  align-items: center;
  cursor: default;
  background-color: #f2f6f8 !important;
  font-family: "Latam_Sans_Regular_Italic";
  color: #4e6573 !important;
  font-size: 12px;
  font-weight: 900;
}
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.view_more mat-chip-list .mat-chip-list-wrapper {
  justify-content: center;
}
.next-flight-home .container-activities {
  justify-content: start !important;
  margin-top: 2px;
}
.next-flight-home .container-activities .dat {
  margin-left: auto !important;
}
.next-flight-home .container-activities .cod-iata {
  font-size: 20px !important;
}
.next-flight-home .container-activities .hora {
  font-size: 10px !important;
}
.next-flight-home .container-activities .arrow-middle i {
  font-size: 23px !important;
  padding: 0px 22px !important;
}
.next-flight-home .container-activities .date-flight.month {
  font-size: 10px;
  font-family: "Latam_Sans_Black", arial, sans-serif;
}
.next-flight-home .container-activities .day-week {
  font-size: 14px !important;
}
.next-flight-home .container-activities .date-flight {
  line-height: 18px !important;
}
.next-flight-home .footer-flight .title-footer {
  margin-top: 2px !important;
  font-size: 11px !important;
  font-family: "Latam_Sans_Light_Italic" !important;
}
@media(max-width: 350px){
  .indicatorText{
    margin-left: 10px;
  }
}
@media (max-width: 390px) {
  .next-flight-home .footer-flight .title-footer {
    font-size: 9px !important;
  }
}
@media (min-width: 768px) {
  .dashboardNoInfo label{
    font-size: 16px;
    margin-top: 15px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  mat-card.dashboard {
    padding: 5px 10px !important;
  }
  mat-card.dashboard mat-card-title i {
    width: 22px;
    height: 22px;
  }
  mat-card.dashboard mat-card-title i img {
    width: 5px;
    margin-top: -12px;
  }
  .stabilization-dashboard .text-box h2 {
    font-size: 42px !important;
  }
  .stabilization-dashboard .text-box span {
    font-size: 18px !important;
  }
  .next-flight-home .footer-flight .title-footer {
    font-size: 12px !important;
  }
  .next-flight-home .container-activities .cod-iata {
    font-size: 22px !important;
  }
  .next-flight-home .container-activities .hora {
    font-size: 12px !important;
  }
  .next-flight-home .container-activities .arrow-middle i {
    font-size: 23px !important;
    padding: 0px 22px !important;
  }
  .next-flight-home .container-activities .date-flight.month {
    font-size: 12px;
  }
  .next-flight-home .container-activities .day-week {
    font-size: 16px !important;
  }
}
.overflow-control-efficiency{
  height: auto !important;
}
.overflow-control-efficiency mat-sidenav-content{
  overflow: hidden !important;
}
.overflow-reports {
  height: auto !important;
}
.full-screen-modal{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999999999;
  left: 0;
  top: 0;
  background-color: #FFFFFF;
  overflow: hidden;
  animation: enter-animation 0.5s ease-in-out;
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
}

.slideInRight {
  animation: slideInRight 0.5s ease-in-out;
}
@keyframes slideInRightReverse {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRightReverse {
  animation: slideInRightReverse 0.5s ease-in-out;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
.zoomIn {
 animation: zoomIn 1s;
}
@keyframes enter-animation {
  0%   { transform:translateX(100%) }
  100% { transform:translateX(0%)  }
}
@media (min-width: 992px) {
  .view_more mat-chip {
    font-size: 14px !important;
  }
  .graph-info span {
    font-size: 14px !important;
  }
  mat-card.dashboard mat-card-title {
    font-size: 14px;
  }
  mat-card.dashboard mat-card-title i {
    width: 26px;
    height: 26px;
  }
  mat-card.dashboard mat-card-title i img {
    width: 6px;
    margin-top: -10px;
  }
}

/* OTP HOME */

mat-expansion-panel.otp-expansion-panel.mat-expansion-panel {
  border-radius: 6px;
  box-shadow: 0px 13px 40px 0px rgba(176, 192, 237, 0.5);
  padding: 3px;
}
mat-expansion-panel.otp-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover,
mat-expansion-panel.otp-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):focus {
  background: transparent;
}

mat-expansion-panel.otp-expansion-panel .mat-expansion-panel-header {
  padding: 0 13px;
}

mat-expansion-panel.otp-expansion-panel .mat-expansion-panel-header-title {
  color: #4b6574;
  font-family: "Latam_Sans_Regular_italic";
}

mat-expansion-panel.otp-expansion-panel .mat-expansion-panel-header-title span {
  font-family: "Latam_Sans_Black_italic";
}

mat-expansion-panel.otp-expansion-panel .mat-expansion-panel-header-description {
  color: #4f6878;
  font-size: 14px;
  line-height: 1.2;
  font-family: "Latam_Sans_Black_italic";
}

mat-expansion-panel.otp-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}

.badge {
  box-shadow: none;
  min-width: 57px;
  font-family: "latam_sans_black_italic";
}


.card-title-roster {
  color: #476474;
  font-family: "Latam_sans_black_italic";
  font-size: 18px;
  padding-left: 5px;
  padding-top: 5px;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #9aaeba;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white;
}

.clickable{
  cursor: pointer;
}

.col-centered{
  float: none;
  margin: 0 auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label.mat-tab-label-active {
  color: var(--blue-pilot) !important;
 }


.mat-ink-bar.mat-ink-bar {
  background-color: var(--red-header-pilot) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle .mat-slide-toggle-thumb {
  background-color: var( --blue-pilot) !important;
}


  
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: var(--red-header-pilot) !important;
 }
